import Router from "next/router";
import {
	targettedValuePropItems,
	newYorkTargettedValuePropItems,
	landingPageBValuePropItems,
} from "./ValueProps2";
import { cardItemsBTest, targetCardItems } from "../shared/ValuePropCards";
import { StatesWithStatePage } from "utils/types";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import statePages from "config/state-pages.json";
import { useEffect, useMemo } from "react";
import { useAuthContext } from "context/AuthContext";
import { AbTest } from "elements/AbTest";
import AnnouncementBanner from "elements/AnnouncementBanner";
import RealtorReferralPartnerBanner from "./RealtorReferralPartnerBanner";
import Script from "next/script";
import Head from "next/head";
import { HeroSection } from "./HeroSection";
import { MarqueeFeaturedSection } from "./MarqueeFeaturedSection";
import { StatSection } from "./StatSection";
import { BigNumberCounterSection } from "./BigNumberCounterSection";
import { MoreWaysToSaveSection } from "./MoreWaysToSaveSection";
import { TestimonialsSection } from "./TestimonialsSection";
import { SignUpStepsSection } from "./SignUpStepsSection";
import { ViewPricingSection } from "./ViewPricingSection";
import { ExpertsSection } from "./ExpertsSection";
import { FAQSection } from "./FAQSection";
import Header from "layout/Header";
import Footer from "layout/Footer";
import PropertyTax101Section from "./PropertyTax101Section";
import { PartnersBanner } from "./PartnersBanner";

const PAGE_TITLE = "Ownwell: Lower Your Property Taxes";
const PAGE_DESCRIPTION =
	"Save money on your property taxes. With no upfront commitment, our local experts manage your appeal from start to finish. Pay just 25% of what you save. Start your appeal today.";
const STATES_WITH_COUNTIES_BANNER = ["GA", "FL"];

const HAS_BEEN_ON_LANDING_PAGE = "shown_landing_page";

const STATES_WITH_NO_PROPERTY_TAX_PAGE = ["NY", "IL"];

const LandingFancy = ({
	target,
	dynamicTitle,
}: {
	target?: StatesWithStatePage;
	dynamicTitle: string | null;
}) => {
	const { isAuthenticated } = useAuthContext();
	const trackEvent = useEventTracker();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.HOME_PAGE_VISIT,
			data: {
				Variant: target ? target : "false",
			},
		});
	}, []);

	const hasBeenOnLandingPage = useMemo(() => {
		try {
			if (typeof window === "undefined" || window.sessionStorage === undefined)
				return false;
			return !!window.sessionStorage.getItem(HAS_BEEN_ON_LANDING_PAGE);
		} catch {
			console.error("Cannot access sessionStorage");
		}
	}, []);

	useEffect(() => {
		try {
			if (
				isAuthenticated &&
				!hasBeenOnLandingPage &&
				window.sessionStorage !== undefined
			) {
				window.sessionStorage.setItem(HAS_BEEN_ON_LANDING_PAGE, "1");
				Router.push("/account/properties");
			}
		} catch {
			console.error("Cannot access sessionStorage");
		}
	}, [hasBeenOnLandingPage, isAuthenticated]);

	if (target && !statePages.hasOwnProperty(target)) target = undefined;

	const valuePropItems =
		target === "NY"
			? newYorkTargettedValuePropItems
			: target
			? targettedValuePropItems
			: landingPageBValuePropItems;

	const valuePropCards = useMemo(() => {
		if (target === "GA" || target === "WA") {
			return targetCardItems;
		} else {
			return cardItemsBTest;
		}
	}, [target]);

	const showAnnouncementBanner = false;

	return (
		<>
			<style jsx global>
				{`
					body {
						background-color: #edf6ff;
					}
				`}
			</style>
			<Script
				id="ze-snippet"
				strategy="lazyOnload"
				src="https://static.zdassets.com/ekr/snippet.js?key=a6eaeaac-9943-44de-8edd-efde086ea77d"
				onLoad={() => {
					if (typeof window === "undefined") return;
					//@ts-ignore
					window.zESettings = {
						webWidget: {
							helpCenter: {
								originalArticleButton: false,
							},
						},
					};
				}}
			/>
			<div
				id="site-layout"
				className={[showAnnouncementBanner ? "fancy-w-banner" : "fancy"].join(
					" "
				)}>
				<Header>{showAnnouncementBanner && <AnnouncementBanner />}</Header>
				<main id="site-body">
					<div id="landing-fancy">
						<Head>
							<title>{PAGE_TITLE}</title>
							<meta property="title" content={PAGE_TITLE} key="title" />
							<meta property="og:title" content={PAGE_TITLE} key="og:title" />
							<meta
								name="description"
								content={PAGE_DESCRIPTION}
								key="description"
							/>
							<meta
								property="og:description"
								content={PAGE_DESCRIPTION}
								key="og:description"
							/>
						</Head>
						<RealtorReferralPartnerBanner />
						<PartnersBanner />
						<HeroSection
							title={
								dynamicTitle
									? dynamicTitle
									: `Save money on your ${
											target ? statePages[target].name + " " : ""
									  }property taxes`
							}
							isTarget={!!target}
						/>
						<div className="cloud-gradient-sections">
							<MarqueeFeaturedSection />
							<StatSection />
						</div>
						<BigNumberCounterSection />
						<div className="sky-light-gradient-sections">
							<MoreWaysToSaveSection target={target} />
							<TestimonialsSection />
							<SignUpStepsSection />
							{target && !STATES_WITH_NO_PROPERTY_TAX_PAGE.includes(target) && (
								<PropertyTax101Section target={target} />
							)}
						</div>
						<ViewPricingSection />
						<ExpertsSection />
						<FAQSection />
					</div>
				</main>
				<Footer />
			</div>
		</>
	);
};

export default LandingFancy;
