import Tree1 from "public/hero-illustration/trees/tree-1.svg";
import Tree2 from "public/hero-illustration/trees/tree-2.svg";

import HouseWithBushes from "public/hero-illustration/house/house-with-bushes.svg";

import GrassTop from "public/hero-illustration/grass/grass-top.svg";
import GrassCenter from "public/hero-illustration/grass/grass-center.svg";
import GrassBottom from "public/hero-illustration/grass/grass-bottom.svg";

import Sky from "public/hero-illustration/sky/sky.svg";
import SkyBlob1 from "public/hero-illustration/sky/sky-blob-1.svg";
import SkyBlob2 from "public/hero-illustration/sky/sky-blob-2.svg";

import Cloud1 from "public/hero-illustration/clouds/cloud-1.svg";
import Cloud2 from "public/hero-illustration/clouds/cloud-2.svg";
import Cloud3 from "public/hero-illustration/clouds/cloud-3.svg";
import Cloud4 from "public/hero-illustration/clouds/cloud-4.svg";
import Cloud5 from "public/hero-illustration/clouds/cloud-5.svg";

import Couple from "public/hero-illustration/people/couple-walking.svg";
import Family from "public/hero-illustration/people/family.svg";
import PersonWithDog from "public/hero-illustration/people/person-with-dog.svg";

export const HeroIllustration = ({ isTarget }: { isTarget?: boolean }) => {
	const showPeople = () => {
		if (isTarget) {
			return null;
		} else {
			return (
				<>
					<Family id="hero-illustration-family" />
					<PersonWithDog id="hero-illustration-person-with-dog" />
					<Couple id="hero-illustration-couple" />
				</>
			);
		}
	};
	return (
		<div id="landing-hero-illustration">
			<Sky id="hero-illustration-sky" />
			<SkyBlob1 id="hero-illustration-sky-blob-1" />
			<SkyBlob2 id="hero-illustration-sky-blob-2" />

			<Cloud1 id="hero-illustration-cloud-1" />

			<Cloud2 id="hero-illustration-cloud-2" />

			<Cloud3 id="hero-illustration-cloud-3" />

			<Cloud4 id="hero-illustration-cloud-4" />

			<Cloud5 id="hero-illustration-cloud-5" />

			<GrassTop id="hero-illustration-grass-top" />
			<GrassCenter id="hero-illustration-grass-center" />

			<GrassBottom id="hero-illustration-grass-bottom" />

			<Tree1 id="hero-illustration-tree-1" />

			<Tree2 id="hero-illustration-tree-2" />

			<HouseWithBushes id="hero-illustration-house-with-bushes" />

			{showPeople()}
		</div>
	);
};
