import { HeroIllustration } from "./HeroIllustration";
import { HeroAdressInput } from "./HeroAdressInput";
import { HeroValueProps } from "./HeroValueProps";
import { AbTest } from "elements/AbTest";
import { useRouter } from "next/router";

export const HeroSection = ({
	title,
	isTarget,
}: {
	title: string;
	isTarget?: boolean;
}) => {
	const router = useRouter();
	const addressEntryCTACopy = () => {
		if (router.asPath.includes("/lp/welcome")) {
			return (
				<AbTest testName="lp-address-entry-cta">
					{({ variant }) => (
						<>
							{variant === "get-savings-estimate"
								? "Get Savings Estimate"
								: variant === "estimate-my-savings"
								? "Estimate My Savings"
								: variant === "unlock-savings"
								? "Unlock Savings"
								: "Sign Up in 3 Minutes"}
						</>
					)}
				</AbTest>
			);
		} else {
			return undefined;
		}
	};

	return (
		<div id="landing-hero-section">
			<HeroIllustration isTarget={isTarget} />
			<HeroAdressInput title={title} buttonCopy={addressEntryCTACopy()} />
			<HeroValueProps />
		</div>
	);
};
